enum WorkTypesEnums {
    ASSEMBLING = 'Assembling',
    BUILDING_WORKS = 'Building Works',
    CASTING = 'Casting',
    COMMISSIONING = 'Commissioning',
    CONCRETING = 'Concreting',
    CONVERSION_WORKS = 'Conversion Works',
    DECORATING = 'Decorating',
    DESIGNING_DRAWING_WORKS = 'Designing/Drawing Works',
    DEMOLITION = 'Demolition',
    DUMPING = 'Dumping',
    DISASSEMBLING = 'Disassembling',
    DRAWING = 'Drawing',
    DRILLING = 'Drilling',
    ELECTRICAL_WORKS = 'Electrical Works',
    EXCAVATING = 'Excavating',
    FITTING = 'Fitting',
    FOUNDATION_WORKS = 'Foundation Works',
    INSTALLATION = 'Installation',
    LANDSCAPING = 'Landscaping',
    MAINTENANCE = 'Maintenance',
    MARKING = 'Marking',
    PAINTING = 'Painting',
    PILING = 'Piling',
    PLANT_SCAFFOLDING = 'Plant/Scaffolding',
    PLASTERING = 'Plastering',
    PLUMBING = 'Plumbing',
    REFURBISHMENT = 'Refurbishment',
    REDECORATION = 'Redecoration',
    REPLACEMENT = 'Replacement',
    REMOVAL = 'Removal',
    RENOVATION = 'Renovation',
    REVIEWING_DOCUMENTS = 'Reviewing Documents',
    ROAD_WORKS = 'Road Works',
    ROOFING = 'Roofing',
    SCAFFOLDING = 'Scaffolding',
    WELDING = 'Welding',
    TESTING = 'Testing',
    TILING = 'Tiling',
}
enum JobTitleEnum {
    EMPLOYER = 'Employer',
    EMPLOYERS_REPRESENTATIVE = 'Employer’s Representative',
    CONTRACTOR = 'Contractor',
    LOCAL_PUBLIC_AUTHORITY = 'Local/Public Authority',
    SUB_CONTRACTOR = 'Sub-Contractor',
    ARCHITECT = 'Architect',
    CONTRACT_ADMINISTRATOR = 'Contract Administrator',
    FUNDER = 'Funder',
    QUANTITY_SURVEYOR = 'Quantity Surveyor',
    STATUTORY_UNDERTAKER = 'Statutory Undertaker',
    INSURANCE_PROVIDER = 'Insurance Provider',
    TENANT = 'Tenant',
    PURCHASER = 'Purchaser',
    OTHER_SPECIALIST_WORKER = '‘Other’ Specialist Worker',
    AGENTS = 'Agents',
    BROKER = 'Broker',
    CLERK_OF_WORKS = 'Clerk of Works',
    PARENT_COMPANY = 'Parent Company',
}
enum CompanyTypeEnum {
    LIMITED_LIABILITY_PARTNERSHIP = 'Limited Liability Partnership',
    PARTNERSHIP = 'Partnership',
    PRIVATE_LIMITED_COMPANY = 'Private Limited Company (LTD)',
    SOLE_TRADER = 'Sole Trader/Individual',
}
// 封装的方法
export function getUserPositionTypeText(typeValue: JobTitleEnum): string {
    return JobTitleEnum[typeValue] || typeValue;
}
export function getWorkTypesText(typeValue: WorkTypesEnums): string {
    return WorkTypesEnums[typeValue] || typeValue;
}
export function getCompanyTypeText(typeValue: CompanyTypeEnum): string {
    return CompanyTypeEnum[typeValue] || typeValue;
}
enum FileTypeEnum {
    'text/plain' = 'text',
    'text/html' = 'html',
    'application/json' = 'json',
    'image/jpeg' = 'image',
    'image/png' = 'image',
    'image/gif' = 'image',
    'audio/mpeg' = 'audio',
    'audio/wav' = 'audio',
    'video/mp4' = 'video',
    'video/quicktime' = 'video',
    'application/pdf' = 'pdf',
    'application/msword' = 'word',
    'application/vnd.ms-excel' = 'excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' = 'xlsx',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document' = 'docx',
}
export function getFileTypeText(typeValue: string): string {
    const text = typeValue ? typeValue.split('/')[0] : '-';
    return FileTypeEnum[typeValue] || text;
}
