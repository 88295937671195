import React from 'react';

import Index from '@/views/pages/index';
import Statistics from '@/views/pages/statistics';
import UserAdmin from '@/views/pages/userAdmin';
import UserDetail from '@/views/pages/userAdmin/user-detail';
import Project from '@/views/pages/project';
import ProjectDetail from '@/views/pages/project/project-detail';
import Subscription from '@/views/pages/subscription';
import Setting from '@/views/pages/setting';
import Versions from '@/views/pages/setting/version';
const Policy = React.lazy(() => import('@/views/app-page/policy'));
const Teams = React.lazy(() => import('@/views/app-page/teams'));
const Login = React.lazy(() => import('@/views/login'));
const RestPassword = React.lazy(() => import('@/views/resetPassword'));
const WebCallapp = React.lazy(() => import('@/views/app-page/web-callapp'));
const OtherLogin = React.lazy(() => import('@/views/app-page/other-login'));
const ProjectChangeAuthorization = React.lazy(() => import('@/views/app-page/project-change-authorization'));
const PersonnelChangeAuthorization = React.lazy(() => import('@/views/app-page/personnel-change-authorization'));
export interface RouteConfigDeclaration {
    path: string;
    name?: string;
    exact?: boolean;
    auth?: boolean;
    isRedirect?: boolean;
    isDynamic?: boolean;
    loadingFallback?: string;
    component: any;
    childrens?: RouteConfigDeclaration[];
    isShowOnNav?: boolean;
    KeepAlive?: boolean;
    icon?: any;
}
const routes: RouteConfigDeclaration[] = [
    {
        path: '/login',
        component: Login,
        exact: true,
        auth: false,
    },
    {
        path: '/restPassword',
        component: RestPassword,
        exact: true,
        auth: false,
    },
    {
        path: '/web-callapp',
        component: WebCallapp,
        exact: true,
        auth: false,
    },
    {
        path: '/policy',
        component: Policy,
        exact: true,
        auth: false,
    },
    {
        path: '/terms',
        component: Teams,
        exact: true,
        auth: false,
    },
    {
        path: '/other-login',
        component: OtherLogin,
        exact: true,
        auth: false,
    },
    {
        path: '/project-change-authorization',
        component: ProjectChangeAuthorization,
        exact: true,
        auth: false,
    },
    {
        path: '/personnel-change-authorization',
        component: PersonnelChangeAuthorization,
        exact: true,
        auth: false,
    },
    {
        path: '/*',
        component: Index,
        exact: false,
        childrens: [
            {
                name: 'Statistics',
                path: '/statistics',
                component: Statistics,
                exact: true,
                isShowOnNav: true,
                KeepAlive: true,
                icon: 'Statistics',
            },
            {
                name: 'User Admins',
                path: '/user',
                component: UserAdmin,
                exact: true,
                isShowOnNav: true,
                KeepAlive: true,
                icon: 'Contractors',
            },
            {
                name: 'user-detail',
                path: '/user-detail',
                component: UserDetail,
                exact: true,
                isShowOnNav: false,
                KeepAlive: true,
            },
            {
                name: 'Projects',
                path: '/project',
                component: Project,
                exact: true,
                isShowOnNav: true,
                KeepAlive: true,
                icon: 'Project',
            },
            {
                name: 'ProjectDetail',
                path: '/project-detail',
                component: ProjectDetail,
                exact: true,
                isShowOnNav: false,
                KeepAlive: false,
            },
            {
                name: 'Subscriptions',
                path: '/subscription',
                component: Subscription,
                exact: true,
                isShowOnNav: true,
                KeepAlive: false,
                icon: 'Subscription',
            },
            {
                name: 'Settings',
                path: '/setting',
                component: Setting,
                exact: true,
                isShowOnNav: true,
                KeepAlive: true,
                icon: 'Version',
            },
            {
                name: 'versions',
                path: '/setting/versions',
                component: Versions,
                exact: true,
                isShowOnNav: false,
                KeepAlive: false,
            },
        ],
    },
];

export default routes;
