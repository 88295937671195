import React, { useEffect } from 'react';
import '../style/index.scss';
import { getUserPositionTypeText } from '@/typings/apiType';
const Profile = (props: any) => {
    return (
        <div className="profile_box">
            <div className="r-top info_box">
                <p className="info_tit">Email:</p>
                <p className="info_msg">{props.userDetail.email} </p>
            </div>
            <div className="r-top info_box">
                <p className="info_tit">Job Title:</p>
                <p className="info_msg">
                    {props.userDetail.jobTitle ? getUserPositionTypeText(props.userDetail.jobTitle) : '-'}
                </p>
            </div>
            <div className="r-top info_box">
                <p className="info_tit">Business Entity Type: </p>
                <p className="info_msg">{props.userDetail.businessTypes} </p>
            </div>
            <div className="r-top info_box">
                <p className="info_tit">Phone: </p>
                <p className="info_msg">{props.userDetail.mobileNumber ?? '-'}</p>
            </div>
            <div className="r-top info_box">
                <p className="info_tit">Registration Date: </p>
                <p className="info_msg">{props.userDetail.createTime}</p>
            </div>
        </div>
    );
};

export default Profile;
