import axios from '../utils/index';
import { Pat } from '@/typings/request';
const subscriptionApi = {
    getProjectList(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/project-entity/list`, {
                params,
            })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getProjectInfo(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/project-entity/info/${params.projectId}`, {
                params,
            })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    deleteProject(params: Pat, callback: (T: any) => void) {
        axios
            .delete(`/project-entity/delete/${params.projectId}`, {
                params,
            })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getProjectFolderList(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/folder-entity/list`, {
                params,
            })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    deleteProjectFolder(params: Pat, callback: (T: any) => void) {
        axios
            .delete(`/folder-entity/delete/${params.fileId}`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },

    getVariationAuditEntityInfo(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/variation-audit-entity/info/${params.variationAuditId}`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    setVariationAuditEntity(params: Pat, callback: (T: any) => void) {
        axios
            .put(`/variation-audit-entity/update`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    downloadFile(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/folder-entity/download?url=${params.url}`, { responseType: 'blob' })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
};
export default subscriptionApi;
