import axios from '../utils/index';
import { Pat, HttpResponse } from '@/typings/request';
const loginApi = {
    getSecret(callback: (T: any) => void) {
        axios
            .get(`/v1/public/rsa/get-publicKey`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    login(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/admin/login`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    logOut(callback: (T: any) => void) {
        axios
            .post(`/admin/logout`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    forgotPassword(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/admin/sendSmsCode`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    updatePassword(data: Pat, callback: (T: any) => void) {
        axios
            .put(`/admin/changePassword`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    checkValidatecode(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/admin/checkSmsCode`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
};

export default loginApi;
