import axios from '../utils/index';
import { Pat, HttpResponse } from '@/typings/request';
const userApi = {
    getUserList(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/user/list`, {
                params,
            })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    disableUser(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/admin/user/disabled`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    deleteUser(params: Pat, callback: (T: any) => void) {
        axios
            .delete(`/admin/user/delete/${params.userId}`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getUserDetail(params: Pat, callback: (T: any) => void) {
        axios
            .put(`/admin/user/info/${params.userId}`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getTeamList(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/team-entity/list`, { params })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    editTeamInfo(params: Pat, callback: (T: any) => void) {
        axios
            .put(`/team-entity/update`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    delTeamInfo(params: Pat, callback: (T: any) => void) {
        axios
            .delete(`/team-entity/delete/${params.teamId}`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getUserProjectList(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/project-entity/list`, { params })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getUserSubscriptionList(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/subscription/list`, { params })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
};
export default userApi;
