import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './style/index.scss';
import api from '@/api/settings';
import { defaultInfo } from '@/typings/allType';
const Statistics = (props: any) => {
    useEffect(() => getInfo(), []);
    const navigate = useNavigate();
    const [status, setStatus] = useState('showData');
    const getInfo = () => {
        setStatus('loading');
        api.getStatisticsInfo(res => {
            const { data, code, message } = res;
            if (code === 200) {
                console.log(data);
                setStatistics([
                    {
                        label: 'Subscription revenue',
                        value: `£${
                            data.totalRevenue && data.totalRevenue !== null
                                ? window.format.formatMoney(data.totalRevenue)
                                : '0.00'
                        }`,
                    },
                    {
                        label: 'Average revenue per user',
                        value: `£${
                            data.netRevenue && data.netRevenue !== null
                                ? window.format.formatMoney(data.netRevenue)
                                : '0.00'
                        }`,
                    },
                    {
                        label: 'Total number of subscriptions',
                        value: data.subscriptions !== null ? data.subscriptions : 0,
                    },
                ]);
                setState({
                    ...state,
                    computedProjectList:
                        data.numbers && data.numbers !== null
                            ? data.numbers.map(item => {
                                  return Object.assign(item, {
                                      completedAmount: item.completedAmount !== null ? item.completedAmount : '0',
                                  });
                              })
                            : [],
                    ProjectToBeCompletedList:
                        data.projects && data.projects !== null
                            ? data.projects.map(item => {
                                  return Object.assign(item, {
                                      completeDate:
                                          item.completeDate !== null
                                              ? window.format.foramtTimeDMY_easy(+item.completeDate)
                                              : '-',
                                  });
                              })
                            : [],
                });
            } else {
                window.format.alert('error', message);
                setStatus('empty');
            }
        });
    };
    const linkToPage = (type: number, val: defaultInfo) => {
        if (type === 1) {
            window.sessionStorage.setItem('userInfo', JSON.stringify(val));
            navigate('/user-detail');
            return;
        }
        window.sessionStorage.setItem('projectInfo', JSON.stringify(val));
        navigate('/project-detail');
    };
    const [state, setState] = useState({
        pageNo: 1,
        totalRecord: 0,
        search: '',
        ProjectToBeCompletedList: [] as defaultInfo[],
        computedProjectList: [] as defaultInfo[],
    });
    const [statistics, setStatistics] = useState([
        { label: 'Subscription revenue', value: '£0.00' },
        { label: 'Average revenue per user', value: '£0.00' },
        { label: 'Total number of subscriptions', value: '0' },
    ]);
    return (
        <div className="statistics_main page_mainBox">
            <h2>Statistics</h2>
            <p className="top_tips">Subscription statistics</p>
            <div className="statistics_top r-left">
                {statistics.map((item, index) => {
                    return (
                        <div className="statistics_item" key={item.label}>
                            <p className="statistics_item_label">{item.label}</p>
                            <p className="statistics_item_value">{item.value}</p>
                        </div>
                    );
                })}
            </div>
            <div className="r-left tablex_main">
                <div className="computedNum_box">
                    <p className="tab_tips">Number of completed projects</p>
                    <ul className="tab_list">
                        <li className="r-left tab_header">
                            <div className="maxW315">User Admin Name</div>
                            <div className="marLeft minW" style={{ width: 150, textAlign: 'center' }}>
                                Completed Amount
                            </div>
                        </li>
                        {state.computedProjectList.length ? (
                            state.computedProjectList.map((item, index) => {
                                return (
                                    <li
                                        className="r-left tab_row"
                                        key={item.userId}
                                        onClick={() => linkToPage(1, item)}
                                    >
                                        <div className="maxW315 oneLine_text">{item.userName ?? '-'}</div>
                                        <div className="marLeft" style={{ width: 150, textAlign: 'center' }}>
                                            {item.completedAmount}
                                        </div>
                                    </li>
                                );
                            })
                        ) : (
                            <div className="no-data">No data</div>
                        )}
                    </ul>
                </div>
                <div className="computedNum_box">
                    <p className="tab_tips">Project to be completed</p>
                    <ul className="tab_list">
                        <li className="r-left tab_header">
                            <div className="maxW215">Project Name</div>
                            <div className="userName_box minW125">User Admin Name</div>
                            <div className="minW marLeft">Completion Date</div>
                        </li>
                        {state.ProjectToBeCompletedList.length ? (
                            state.ProjectToBeCompletedList.map((item, index) => {
                                return (
                                    <li
                                        className="r-left tab_row"
                                        key={item.projectId}
                                        onClick={() => linkToPage(2, item)}
                                    >
                                        <div className="maxW215 oneLine_text">
                                            {item.projectName ? item.projectName : '-'}
                                        </div>
                                        <div className="userName_box minW125 oneLine_text">{item.userName ?? '-'}</div>
                                        <div className="minW marLeft">{item.completeDate ?? '-'}</div>
                                    </li>
                                );
                            })
                        ) : (
                            <div className="no-data">No data</div>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
};
export default Statistics;
