import dayjs from 'dayjs';
/* eslint-disable */
import { message } from 'antd';
import { getFileTypeText } from '@/typings/apiType';

export default {
    alert(type: any, msg: string) {
        if (!msg) return;
        message[type](msg);
    },
    isNumber(value: any): boolean {
        const reg = /^[0-9]*$/;
        return reg.test(value);
    },
    removeFormatMoney(s: string) {
        return parseFloat(s.replace(/[^\d\.-]/g, ''));
    },
    formatMoney(s: string, type?: number) {
        if (/[^0-9\.]/.test(s)) return '0.00';
        if (s == null || s == 'null' || s == '') return '0.00';
        s = s.toString().replace(/^(\d*)$/, '$1.');
        s = (s + '00').replace(/(\d*\.\d\d)\d*/, '$1');
        s = s.replace('.', ',');
        var re = /(\d)(\d{3},)/;
        while (re.test(s)) s = s.replace(re, '$1,$2');
        s = s.replace(/,(\d\d)$/, '.$1');
        if (type == 0) {
            var a = s.split('.');
            if (a[1] == '00') {
                s = a[0];
            }
        }
        return s;
    },
    isEmail(str: string) {
        const reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
        return reg.test(str);
    },
    isWebSite(str: string) {
        const reg =
            /^\b(((https?|ftp):\/\/)?[-a-z0-9]+(\.[-a-z0-9]+)*\.(?:com|co|io|edu|gov|int|mil|net|org|biz|info|name|museum|asia|coop|aero|[a-z][a-z]|((25[0-5])|(2[0-4]\d)|(1\d\d)|([1-9]\d)|\d))\b(\/[-a-z0-9_:\@&?=+,#.!\/~%\$]*)?)$/i;
        return reg.test(str);
    },
    isPassword(str: string) {
        const len: number = str ? str.split('').length : 0;
        return len >= 8 && len <= 16;
    },
    isBlank(str: string): boolean {
        return str == null || false || str === '' || str.trim() === '' || str.toLocaleLowerCase().trim() === 'null';
    },
    getFilePathName(str: string) {
        if (!str || str === null) return;
        const urlObj = new URL(str ?? '');
        return urlObj ? urlObj.pathname.substring(1) : '';
    },
    getPageQuery(url: string) {
        const regex = /from=([^&#]+)/;
        const match = url.match(regex);
        if (match && match[1]) {
            const fromValue = match[1];
            return decodeURIComponent(fromValue);
        }
        return '';
    },
    getWebsiteShow(url: string | null) {
        if (!url || url === null) {
            return '-';
        }
        // if (url.includes('://')) {
        //     return url.split('://')[1];
        // }
        const cleanedUrl = url.replace(/^(https?:\/\/)/, '');
        const finalUrl = cleanedUrl.replace(/\/$/, '');
        return finalUrl;
    },
    getFileExtensionNameDownload(str: string) {
        if (!str || str === null) return;
        const url = new URL(str ?? '');
        const params = new URLSearchParams(url.search);
        const type = params.get('X-NT-ContentType') ? params.get('X-NT-ContentType') : '';
        // return type?.includes('image') ? 'image' : type;
        return type ? type : '';
    },
    getFileExtensionName(str: string) {
        if (!str || str === null) return;
        const url = new URL(str ?? '');
        const params = new URLSearchParams(url.search);
        const type = params.get('X-NT-ContentType') ? params.get('X-NT-ContentType') : '';
        // return type?.includes('image') ? 'image' : type;
        return type ? getFileTypeText(type) : '';
    },
    getFileNewName(str: string) {
        if (!str || str === null) return;
        const url = new URL(str ?? '');
        const params = new URLSearchParams(url.search);
        const name = params.get('X-NT-OriginalFullName') ? params.get('X-NT-OriginalFullName') : '';
        return name;
    },
    getFileCreateTime(str: string) {
        if (!str || str === null) return;
        const url = new URL(str ?? '');
        const params = new URLSearchParams(url.search);
        const date = params.get('X-NT-CreateTime') ? params.get('X-NT-CreateTime') : '';
        return date ? this.foramtTimeDMYHM_easy(+date) : '-';
    },
    formatFileSize(limit: number | string) {
        limit = limit ? Number(limit) : 0;
        var size = '';
        if (limit < 1024) {
            size = limit.toFixed(1) + 'B';
        } else if (limit < 1024 * 1024) {
            size = (limit / 1024).toFixed(1) + 'Kb';
        } else if (limit < 1024 * 1024 * 1024) {
            size = (limit / (1024 * 1024)).toFixed(1) + 'Mb';
        } else {
            size = (limit / (1024 * 1024 * 1024)).toFixed(1) + 'Gb';
        }
        var sizeStr = size + '';
        var index = sizeStr.indexOf('.');
        var dou = sizeStr.substr(index + 1, 2);
        if (dou == '00') {
            return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2);
        }
        return size;
    },
    getFileSize(str: string) {
        if (!str || str === null) return '0 kb';
        const url = new URL(str ?? '');
        const params = new URLSearchParams(url.search);
        const size =
            params.get('X-NT-ContentLength') && params.get('X-NT-ContentLength') !== null
                ? (params.get('X-NT-ContentLength') as any)
                : '0 kb';
        // return this.formatFileSize(size);
        return size ? `${(size / 1024).toFixed(0)} kb` : '0 kb';
    },
    getFirstLetter(str: string) {
        if (typeof str !== 'string' || str.length === 0) {
            return '';
        }
        const firstChar = str.charAt(0);
        const capitalizedChar = firstChar.toUpperCase();
        return capitalizedChar;
    },
    addIndexToMatches(arr, property, value) {
        if (!Array.isArray(arr) || arr.length === 0) {
            return arr;
        }
        let index = 1;
        return arr.map(obj => {
            if (obj[property] === value) {
                obj.index = index++;
            }
            return obj;
        });
    },
    extractFilename(url: string) {
        const regex = /\/([^/]+)\?/;
        const matches = url.match(regex);

        if (matches && matches.length > 1) {
            return matches[1];
        }

        return '';
    },
    debounce(fnName: any, time: number) {
        let timeout: any = null;
        return function () {
            if (timeout) {
                clearTimeout(timeout);
            }
            timeout = setTimeout(() => {
                fnName();
            }, time);
        };
    },
    timeFormatYear(val: any) {
        let date: any = new Date(val * 1000);
        if (!date) {
            date = Date.now();
        }
        const time = date.toLocaleDateString().replace(/\//g, '/');
        return time;
    },
    formatNumber(s: string | null): string | undefined {
        if (!s) {
            return '-';
        }
        if (s.indexOf('+44') !== -1) {
            s = s.split('+44')[1];
            return '+44 ' + s.slice(0, 2) + ' ' + s.slice(2, 6) + ' ' + s.slice(6, 10);
        }
        if (s.length == 11) {
            return s.slice(0, 3) + ' ' + s.slice(3, 7) + ' ' + s.slice(7, 11);
        } else if (s.length == 8) {
            return s.slice(0, 4) + ' ' + s.slice(4, 8);
        } else if (s.length == 9) {
            return s.slice(0, 3) + ' ' + s.slice(3, 6) + ' ' + s.slice(6, 9);
        } else if (s.length == 10) {
            return s.slice(0, 2) + ' ' + s.slice(2, 4) + ' ' + s.slice(6, 10);
        } else {
            return s;
        }
    },
    getLastCurrentDays(days: number) {
        const startDate = dayjs().subtract(days, 'days').startOf('day').unix();
        const endDate = dayjs().subtract(0, 'days').endOf('day').unix();
        const date = [startDate, endDate];
        return date;
    },
    getCurremtYear(): number {
        const year = dayjs().year();
        return year;
    },
    foramtTimeDMY(date: any) {
        date = date ? Number(date) : new Date();
        return dayjs(date).format('DD/MM/YYYY');
    },
    foramtTimeDMYHM(date: any) {
        date = date ? Number(date) : new Date();
        return dayjs(date).format('DD/MM/YYYY HH:mm');
    },
    foramtTimeDMYTime(date: any) {
        date = date ? Number(date) : new Date();
        return dayjs(date).format('DD MMM YYYY');
    },
    foramtTimePush(date: any) {
        date = date ? date : new Date();
        return dayjs(date).format('hh:mm a DD MMM YYYY');
    },
    foramtTimeDMYHM_easy(date: any) {
        date = date ? date : new Date();
        return dayjs(date).format("D MMM 'YY HH:mm");
    },
    foramtTimeDMY_easy(date: any) {
        date = date ? date : new Date();
        return dayjs(date).format("D MMM 'YY");
    },
    getPreMonth() {
        var year2: number = dayjs().year();
        var month2: number = dayjs().month();
        if (month2 == 0) {
            year2 = year2 - 1;
            month2 = 12;
        }
        var preMonth = `${month2 < 10 ? '0' + month2 : month2}/${year2}`;
        return preMonth;
    },
    validateInput(input) {
        if (!input.toString()) {
            return '';
        }
        input = input
            .replace(/[^\d.]/g, '')
            .replace(/^\./g, '')
            .replace(/\.{2,}/g, '')
            .replace('.', '$#$')
            .replace(/\./g, '')
            .replace('$#$', '.')
            .replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3');
        return input;
    },
};
