import React, { useEffect, useState } from 'react';
import '../style/index.scss';
import TableBox from '@/components/tableBox';
import closeIcon from '@/assets/images/Icons_Cross.png';
import { Button, Input, Modal, Select } from 'antd';
import api from '@/api/user';
import { getUserPositionTypeText } from '@/typings/apiType';
const Team = props => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [opeationModalOpen, setOpeationInfoModalOpen] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);
    const [state, setState] = useState({
        firstName: '',
        lastName: '',
        email: '',
        title: '',
        isAuthPersional: true,
        rate: '',
        teamId: '',
    });
    useEffect(() => {
        setIsEmpty(Object.keys(state).findIndex(v => !state[v].toString()) !== -1);
    }, [state]);

    const columns = [
        {
            title: 'Name',
            dataIndex: '',
            key: 'firstName',
            render: val => (
                <div className="cell-txt r-left">
                    {val.firstName} {val.lastName}
                </div>
            ),
        },
        {
            title: 'Email',
            dataIndex: '',
            key: 'redeeemailmDate',
            render: val => <div className="cell-txt">{val.email}</div>,
        },
        {
            title: 'Job Title',
            dataIndex: '',
            key: 'jobTitle',
            render: val => <div className="cell-txt">{val.jobTitle ? getUserPositionTypeText(val.jobTitle) : '-'}</div>,
        },
        {
            title: 'Rate',
            dataIndex: '',
            key: 'rate',
            render: val => <div className="cell-txt">£{val.rate ?? '0.00'}</div>,
        },
        {
            title: 'Authorised Personnel',
            dataIndex: '',
            key: 'authorisedPersonnel',
            render: val => <div className="cell-txt">{val.authorisedPersonnel ? 'Yes' : 'No'}</div>,
        },
        {
            title: '',
            dataIndex: '',
            key: 'workoutId',
            render: val => (
                <div className="r-left">
                    <div className="edit_btn r-center" onClick={() => operation(val, 1)}>
                        Edit
                    </div>
                    <div className="remove_btn r-center" onClick={() => operation(val, 0)}>
                        Remove
                    </div>
                </div>
            ),
        },
    ];
    const operation = (val, type) => {
        setState({
            firstName: val.firstName,
            lastName: val.lastName,
            email: val.email,
            title: val.jobTitle === 'CONTRACTOR' ? '3' : val.jobTitle === 'EMPLOYER' ? '1' : '2',
            isAuthPersional: val.authorisedPersonnel,
            rate: val.rate && val.rate !== null ? val.rate : '',
            teamId: val.teamId,
        });
        if (type === 1) {
            setIsModalOpen(true);
        } else {
            setOpeationInfoModalOpen(true);
        }
    };
    const hideModal = () => {
        setIsModalOpen(false);
    };
    const [loading, setLoading] = useState(false);
    const confirmClick = () => {
        if (!window.format.isEmail(state.email)) {
            window.format.alert('error', 'Please enter a valid email address');
            return;
        }
        setLoading(true);
        const params = {
            authorisedPersonnel: state.isAuthPersional,
            email: state.email,
            firstName: state.firstName,
            jobTitle:
                state.title === '1' ? 'EMPLOYER' : state.title === '2' ? 'EMPLOYERS_REPRESENTATIVE' : 'CONTRACTOR',
            lastName: state.lastName,
            rate: state.rate ? state.rate.split(',').join('') : '0.00',
            teamId: state.teamId,
        };
        api.editTeamInfo(params, res => {
            const { code, message, data } = res;
            if (code === 200) {
                props.getList();
                setIsModalOpen(false);
            } else {
                window.format.alert('error', message);
            }
            setLoading(false);
        });
    };
    const confirmOpeationClick = () => {
        setLoading(true);
        api.delTeamInfo({ teamId: state.teamId }, res => {
            const { code, message, data } = res;
            if (code === 200) {
                props.getList();
                setOpeationInfoModalOpen(false);
            } else {
                window.format.alert('error', message);
            }
            setLoading(false);
        });
    };
    return (
        <div className="team_box">
            <h4>Total team members: {props.teamNum ?? 0}</h4>
            <TableBox
                status={props.status}
                columns={columns}
                data={props.tableData}
                rowKey={val => val.teamId}
                height={false}
                className="is_clickable r-table table_main"
                rowClassName={() => 'r-table_row'}
            />

            <Modal
                className="r-modal modal_no_padding"
                maskClosable={false}
                centered={true}
                open={isModalOpen}
                closeIcon={<img src={closeIcon} alt="" className="close-icon" />}
                onCancel={hideModal}
                footer={''}
                key={1}
                style={{ width: 542 }}
                title={[
                    <div className="modal-top-title" style={{ marginBottom: 40 }}>
                        Edit Team Member
                    </div>,
                ]}
            >
                <div className="modal_form">
                    <p className="form_tip">First Name</p>
                    <Input
                        className="r-input"
                        maxLength={20}
                        value={state.firstName}
                        onInput={(e: any) => setState({ ...state, firstName: e.target.value })}
                    />
                    <p className="form_tip">Last Name</p>
                    <Input
                        className="r-input"
                        maxLength={20}
                        value={state.lastName}
                        onInput={(e: any) => setState({ ...state, lastName: e.target.value })}
                    />
                    <p className="form_tip">Email Address</p>
                    <Input
                        className="r-input"
                        value={state.email}
                        onInput={(e: any) => setState({ ...state, email: e.target.value })}
                    />
                    <p className="form_tip">Job Title</p>
                    <Select
                        style={{ width: '100%' }}
                        className="r-select"
                        value={state.title ? state.title : void 0}
                        placeholder="Select"
                        suffixIcon={<i className="sel_arrow_icon"></i>}
                        onChange={val => {
                            setState({ ...state, title: val });
                        }}
                        options={[
                            { value: '1', label: 'Employer' },
                            { value: '2', label: 'Employer’s Representative' },
                            { value: '3', label: 'Contractor' },
                        ]}
                    />
                    <p className="form_tip">Authorised Personnel</p>
                    <div className="r-left">
                        <span
                            className={`auth_btn r-center ${state.isAuthPersional ? 'active' : ''}`}
                            style={{ marginRight: 10 }}
                            onClick={() => setState({ ...state, isAuthPersional: true })}
                        >
                            Yes
                        </span>
                        <span
                            className={`auth_btn r-center ${!state.isAuthPersional ? 'active' : ''}`}
                            onClick={() => setState({ ...state, isAuthPersional: false })}
                        >
                            No
                        </span>
                    </div>
                    <p className="form_tip">Rate</p>
                    <Input
                        className="r-input"
                        value={state.rate}
                        suffix={<span style={{ color: '#A3A3A3' }}>£/h</span>}
                        onInput={(e: any) => {
                            e.target.value = window.format.validateInput(e.target.value);
                            setState({ ...state, rate: e.target.value });
                        }}
                        onFocus={() => {
                            setState({ ...state, rate: state.rate ? state.rate.split(',').join('') : '' });
                        }}
                        onBlur={e => {
                            setState({ ...state, rate: state.rate ? window.format.formatMoney(e.target.value) : '' });
                        }}
                    />

                    <div style={{ marginTop: 60 }}>
                        <Button
                            className="r-primary_btn"
                            type="primary"
                            style={{ width: '100%', marginBottom: 20 }}
                            disabled={isEmpty}
                            loading={loading}
                            onClick={() => confirmClick()}
                        >
                            Confirm
                        </Button>
                    </div>
                </div>
            </Modal>

            <Modal
                className="r-modal modal_no_padding"
                maskClosable={false}
                centered={true}
                open={opeationModalOpen}
                closeIcon={<img src={closeIcon} alt="" className="close-icon" />}
                onCancel={() => setOpeationInfoModalOpen(false)}
                footer={''}
                key={2}
                style={{ width: 542 }}
                title={[
                    <div className="modal-top-title" style={{ marginBottom: 40 }}>
                        Remove
                    </div>,
                ]}
            >
                <div className="modal_form">
                    <p className="modal_tips">
                        Are you sure you want to remove {state.firstName} {state.lastName} from the team?
                    </p>
                    <div className="r-left" style={{ marginTop: 50 }}>
                        <Button
                            className="r-defaultBlick_btn"
                            type="primary"
                            style={{ width: '100%', margin: '0 20px 20px 0' }}
                            onClick={() => setOpeationInfoModalOpen(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="r-dergerMedium_btn"
                            type="primary"
                            loading={loading}
                            style={{ width: '100%', marginBottom: 20 }}
                            onClick={() => confirmOpeationClick()}
                        >
                            Confirm
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Team;
