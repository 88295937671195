import React, { useState } from 'react';
import '../style/index.scss';
import TableBox from '@/components/tableBox';
import { useNavigate } from 'react-router-dom';

const Project = props => {
    const navigate = useNavigate();
    const columns = [
        {
            title: 'Project Name',
            dataIndex: '',
            width: 350,
            key: 'projectName',
            render: val => <div className="cell-txt r-left">{val.projectName ? val.projectName : '-'}</div>,
        },
        {
            title: 'Project ID',
            dataIndex: 'projectId',
            key: 'projectId',
        },
        {
            title: 'Start Date',
            dataIndex: '',
            key: 'startDate',
            render: val => <div className="cell-txt">{val.startDate ?? '-'}</div>,
        },
        {
            title: 'Completion Date',
            dataIndex: '',
            key: 'completionDate',
            render: val => <div className="cell-txt">{val.completionDate ?? '-'}</div>,
        },
        {
            title: 'Status',
            dataIndex: '',
            key: 'projectStatus',
            render: val => (
                <div className="cell-txt" style={{ color: val?.color }}>
                    {val?.text}
                </div>
            ),
        },
    ];
    const handClickRow = val => {
        window.sessionStorage.setItem('projectInfo', JSON.stringify(val));
        navigate('/project-detail');
    };
    return (
        <div className="project_box">
            <div className="r-left">
                <h4>Pending: {props.projectNum.pending ?? '0'}</h4>
                <h4 style={{ margin: '0 50px 20px 50px' }}>Active: {props.projectNum.active ?? '0'}</h4>
                <h4>Completed: {props.projectNum.completed ?? '0'}</h4>
            </div>
            <TableBox
                status={props.status}
                columns={columns}
                data={props.tableData}
                emptyText={{ emptyText: <div className="table_emptyBox">The user has not created the project</div> }}
                rowKey={val => val.workoutRewardsId}
                height={false}
                className="is_clickable r-table table_main click_row"
                rowClassName={() => 'r-table_row'}
                onRow={record => {
                    handClickRow(record);
                }}
            />
        </div>
    );
};

export default Project;
