import { Button, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import Information from './components/information';
import ProjectValue from './components/ProjectValue';
import KeyPersonnelDetails from './components/KeyPersonnelDetails';
import Folders from './components/Folders';
import Pagination from '@/components/pagination';
import closeIcon from '@/assets/images/Icons_Cross.png';
import { useNavigate } from 'react-router-dom';
import api from '@/api/project';
import { defaultInfo } from '@/typings/allType';
const ProjectDetail = () => {
    useEffect(() => {
        const info = JSON.parse(window.sessionStorage.getItem('projectInfo') ?? '{}');
        if (info.projectId) {
            setState({ ...state, info });
            getProjectInfo(info.projectId);
        }
    }, []);
    const navigate = useNavigate();
    const [state, setState] = useState({
        info: {
            projectId: '',
        },
    });
    const [projectInfo, setProjectInfo] = useState({
        projectName: '',
        projectId: '',
        startDate: '',
        completionDate: '',
        actualMachineryCost: 0,
        actualMaterialsCosts: 0,
        budgetMachineryCost: 0,
        budgetMaterialsCosts: 0,
        budgetWorkingHours: 0,
        budgetTotalSalary: 0,
        actualTotalSalary: 0,
        actualWorkingHours: 0,
        internalUserCount: 0,
        externalUserCount: 0,
    });
    const back = () => {
        navigate(-1);
    };
    // 内部人员 展示列表
    const [tableData, setTableData] = useState<defaultInfo[]>([]);
    const [internalInfo, setInternalInfo] = useState<defaultInfo>({
        pageSize: 10,
        currentPage: 1,
        startIdx: 0,
        endIdx: 10,
        total: 0,
        projectTeamVos: [],
        projectTeamVosShow: [],
    });
    // 外部人员 展示列表
    const [exterbalTableData, setExterbalTableData] = useState<defaultInfo[]>([]);
    const [activeTab, setActiveTab] = useState(1);
    const userTabList = [
        { label: 'Information', key: 1 },
        {
            label: 'Project Value',
            key: 2,
        },
        {
            label: 'Key Personnel Details',
            key: 3,
        },
        {
            label: 'Folders',
            key: 4,
        },
    ];
    const changeTabActive = item => {
        setActiveTab(item.key);
    };
    const handlePageChange = (type: number, index: number, page: number) => {
        const pageSize = 10;
        const currentPage = page;
        const startIdx = (currentPage - 1) * pageSize;
        const endIdx = currentPage * pageSize;
        if (type === 0) {
            const list = internalInfo.projectTeamVos.slice(startIdx, endIdx);
            setInternalInfo({ ...internalInfo, currentPage, startIdx, endIdx, projectTeamVosShow: list });
            setTableData(list);
            return;
        }
        const nowInfo = exterbalTableData[index];
        const list = nowInfo.projectTeamVos.slice(startIdx, endIdx);
        setExterbalTableData(
            exterbalTableData.map((item, i) => {
                if (i === index) {
                    return { ...item, currentPage, startIdx, endIdx, projectTeamVosShow: list };
                }
                return item;
            })
        );
    };
    const getProjectInfo = (id?: string) => {
        api.getProjectInfo({ projectId: id ? id : state.info.projectId }, res => {
            const { code, message, data } = res;
            if (code === 200) {
                data.startDate = data.startDate ? window.format.foramtTimeDMY_easy(+data.startDate) : '-';
                data.completionDate = data.completionDate
                    ? window.format.foramtTimeDMY_easy(+data.completionDate)
                    : '-';
                data.contractCategory = data.contractCategory !== null ? data.contractCategory : '-';
                setProjectInfo(JSON.parse(JSON.stringify(data)));
                if (data.contractingPartyTeamVos && data.contractingPartyTeamVos.length > 0) {
                    data.contractingPartyTeamVos.forEach(element => {
                        element.pageSize = 10;
                        element.currentPage = 1;
                        element.startIdx = 0;
                        element.endIdx = 10;
                        element.total = element.projectTeamVos.length;
                        element.projectTeamVosShow = element.projectTeamVos.slice(0, 10);
                    });
                    const internalList = data.contractingPartyTeamVos.filter(item => item.internal);
                    if (internalList.length > 0) {
                        setTableData(internalList[0].projectTeamVosShow);
                    }
                    const externalList = data.contractingPartyTeamVos.filter(item => !item.internal);
                    setExterbalTableData(externalList);
                }
            } else {
                window.format.alert('error', message);
            }
        });
    };

    const [opeationModalOpen, setOpeationModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const confirmOpeationClick = () => {
        api.deleteProject({ projectId: projectInfo.projectId }, res => {
            const { code, message, data } = res;
            if (code === 200) {
                window.format.alert('success', message);
                setLoading(false);
                setOpeationModalOpen(false);
                navigate(-1);
            } else {
                setLoading(false);
                setOpeationModalOpen(false);
                window.format.alert('error', message);
            }
        });
    };
    return (
        <div className="project_detailMain page_mainBox">
            <div className="r-left">
                <div className="back_box r-left" onClick={back}>
                    <i className="back_icon"></i>
                    Back
                </div>
                <Button className="r-dergerDefault_btn marLeft" onClick={() => setOpeationModalOpen(true)}>
                    Remove
                </Button>
            </div>
            <section className="project_info">
                <div className="r-left projectInfo_top">
                    <div>
                        <p className="project_name">{projectInfo.projectName ?? '-'}</p>
                        <p className="project_tips">
                            {projectInfo.startDate} - {projectInfo.completionDate}
                        </p>
                    </div>
                </div>
                <ul className="r-left" style={{ margin: 0 }}>
                    {userTabList.map((item, index) => (
                        <li
                            key={index}
                            onClick={() => changeTabActive(item)}
                            className={`user_tabItem ${activeTab === item.key ? 'active' : ''}`}
                        >
                            {item.label}
                        </li>
                    ))}
                </ul>
            </section>
            <div className="components_btn">
                {/* {activeComponent} */}
                {activeTab === 1 ? (
                    <Information projectInfo={projectInfo}></Information>
                ) : activeTab === 2 ? (
                    <ProjectValue projectInfo={projectInfo}></ProjectValue>
                ) : activeTab === 3 ? (
                    <KeyPersonnelDetails
                        internalInfo={internalInfo}
                        tableData={tableData}
                        exterbalTableData={exterbalTableData}
                        projectInfo={projectInfo}
                        handlePageChange={handlePageChange}
                    ></KeyPersonnelDetails>
                ) : (
                    <Folders projectInfo={projectInfo}></Folders>
                )}
            </div>

            {/* del or suspend  modal*/}
            <Modal
                className="r-modal modal_no_padding"
                maskClosable={false}
                centered={true}
                open={opeationModalOpen}
                closeIcon={<img src={closeIcon} alt="" className="close-icon" />}
                onCancel={() => setOpeationModalOpen(false)}
                footer={''}
                key={1}
                style={{ width: 542 }}
                title={[
                    <div className="modal-top-title" style={{ marginBottom: 40 }}>
                        Remove
                    </div>,
                ]}
            >
                <div className="modal_form">
                    <p className="modal_tips">
                        Delete will also remove any folders and files that have been added to this project. Are you sure
                        you want to delete this project?
                    </p>
                    <div className="r-left" style={{ marginTop: 50 }}>
                        <Button
                            className="r-defaultBlick_btn"
                            type="primary"
                            style={{ width: '100%', margin: '0 20px 20px 0' }}
                            onClick={() => setOpeationModalOpen(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="r-dergerMedium_btn"
                            type="primary"
                            loading={loading}
                            style={{ width: '100%', marginBottom: 20 }}
                            onClick={() => confirmOpeationClick()}
                        >
                            Confirm
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default ProjectDetail;
