import React, { useState } from 'react';
import '../style/index.scss';
import TableBox from '@/components/tableBox';

const Subscription = props => {
    const columns = [
        {
            title: 'Plan',
            dataIndex: '',
            key: 'subscriptionPlan',
            render: val => <div className="cell-txt r-left">{val.subscriptionPlan ?? '-'}</div>,
        },
        {
            title: 'Subscription date',
            dataIndex: '',
            key: 'startDateMs',
            width: 350,
            render: val => <div className="cell-txt">{val.startDateMs ?? '-'}</div>,
        },
        {
            title: 'Amount',
            dataIndex: '',
            key: 'subscriptionPrice',
            width: 200,
            render: val => <div className="cell-txt">£{val.subscriptionPrice ?? '0.00'}</div>,
        },
    ];
    return (
        <div className="subscription_box">
            <div>
                <div className="r-top info_box">
                    <p className="info_tit">Plan: </p>
                    <p className="info_msg">
                        {props.userDetail.subscriptionPlan !== 'FREE' && props.userDetail.subscriptionPlan !== null
                            ? props.userDetail.subscriptionPlan
                            : 'Free'}
                    </p>
                </div>
                <div className="r-top info_box">
                    <p className="info_tit">Amount: </p>
                    <p className="info_msg">£{props.userDetail.subscriptionAmount}</p>
                </div>
                <div className="r-top info_box">
                    <p className="info_tit">Exp. Date: </p>
                    <p className="info_msg">
                        {props.userDetail.subscriptionExpiryTime ? props.userDetail.subscriptionExpiryTime : '-'}
                    </p>
                </div>
            </div>
            <p className="line_box"></p>
            <div className="r-left">
                <h4>Subscription history: {props.subNum ?? '0'}</h4>
            </div>
            <TableBox
                status={props.status}
                columns={columns}
                data={props.tableData}
                emptyText={{
                    emptyText: (
                        <div className="table_emptyBox" style={{ margin: '100px 0' }}>
                            No Data
                        </div>
                    ),
                }}
                rowKey={val => val.workoutRewardsId}
                height={false}
                className="is_clickable r-table table_main"
                rowClassName={() => 'r-table_row'}
            />
        </div>
    );
};

export default Subscription;
