import React, { useCallback, useEffect, useState } from 'react';
import { Input, DatePicker } from 'antd';
import '../project/style/index.scss';
import TableBox from '@/components/tableBox';
import Pagination from '@/components/pagination';
import { debounce } from 'lodash';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import api from '@/api/subscription';
import { defaultInfo } from '@/typings/allType';
const disabledDate: RangePickerProps['disabledDate'] = current => {
    return current && current > dayjs().endOf('day');
};
const { RangePicker } = DatePicker;
const Subscription = (props: any) => {
    useEffect(() => {
        getList({ ...state });
    }, []);
    const [status, setStatus] = useState('showData');
    const [totalRecord, setTotalRecord] = useState(100);
    const [state, setState] = useState({
        pageNo: 1,
        search: '',
        startTime: '' as any,
        endTime: '' as any,
    });
    const [isSearch, setIsSearch] = useState(false);
    const searchList: any = useCallback(
        debounce(params => {
            getList({ ...params });
        }, 1000),
        []
    );
    const changeTime = val => {
        if (val && val !== null) {
            const startTime = dayjs(val[0]).format('YYYY-MM-DD');
            const endTime = dayjs(val[1]).format('YYYY-MM-DD');
            setState({ ...state, startTime: startTime, endTime, pageNo: 1 });
            getList({ ...state, endTime, startTime, pageNo: 1 });
            return;
        }
        setState({ ...state, endTime: '', startTime: '', pageNo: 1 });
        getList({ ...state, endTime: '', startTime: '', pageNo: 1 });
    };
    const handlePageChange = prop => {
        setState({ ...state, pageNo: prop });
        getList({ ...state, pageNo: prop });
    };
    const getList = val => {
        if (status === 'loading') return;
        const params = {
            pageNum: val.pageNo,
            pageSize: 10,
            startTime: val.startTime ? dayjs(`${val.startTime} 00:00:00`).valueOf() : '',
            endTime: val.endTime ? dayjs(`${val.endTime} 23:59:59`).valueOf() : '',
            keyword: val.search,
        };
        setStatus('loading');
        api.getSubscriptionList(params, res => callback(res));
    };
    const callback = (res: defaultInfo) => {
        const { data, code, message } = res;
        if (code === 200) {
            if (data.records.length > 0) {
                const list = data.records.map(item => {
                    return Object.assign(item, {
                        subscriptionPrice: item.subscriptionPrice
                            ? window.format.formatMoney(+item.subscriptionPrice)
                            : '0.00',
                        startDateMs:
                            item.startDateMs && item.startDateMs !== null
                                ? window.format.foramtTimeDMY_easy(+item.startDateMs)
                                : '-',
                        expiresDateMs:
                            item.expiresDateMs && item.expiresDateMs !== null
                                ? window.format.foramtTimeDMY_easy(+item.expiresDateMs)
                                : '-',
                    });
                });
                setTableData(list);
                setStatus('showData');
                setTotalRecord(data.total);
            } else {
                setStatus('showData');
                setTableData([]);
                setTotalRecord(data.total);
            }
        } else {
            setStatus('empty');
            window.format.alert('error', message);
        }
        setIsSearch(false);
    };
    const [tableData, setTableData] = useState([]);
    const columns = [
        {
            title: 'User Admins Name',
            dataIndex: '',
            key: 'userName',
            render: val => <div className="cell-txt r-left">{val.userName ?? '-'}</div>,
        },
        {
            title: 'Plan',
            dataIndex: '',
            key: 'subscriptionPlan',
            render: val => <div className="cell-txt">{val.subscriptionPlan ?? '-'}</div>,
        },
        {
            title: 'Start Date',
            dataIndex: '',
            key: 'startDateMs',
            render: val => <div className="cell-txt">{val.startDateMs ?? '-'}</div>,
        },
        {
            title: 'Amount',
            dataIndex: '',
            key: 'subscriptionPrice',
            render: val => <div className="cell-txt">£{val.subscriptionPrice ?? '0.00'}</div>,
        },
        {
            title: 'Expire Date',
            dataIndex: '',
            key: 'expiresDateMs',
            render: val => <div className="cell-txt">{val.expiresDateMs ?? '-'}</div>,
        },
    ];
    return (
        <div className="project_main table_page page_mainBox">
            <h2>Subscriptions </h2>
            <div className="workout_top r-left">
                <Input
                    placeholder="Name "
                    style={{ width: 335 }}
                    value={state.search}
                    allowClear
                    onChange={e => {
                        setIsSearch(true);
                        setState({ ...state, search: e.target.value, pageNo: 1 });
                        searchList({ ...state, search: e.target.value, pageNo: 1 });
                    }}
                    className="r-input r-input_serach"
                    prefix={<span className="search_icon"></span>}
                />
                <div className="space15"></div>
                <RangePicker
                    className={`r-datePicker ${state.startTime ? 'r-date_showLine' : ''}`}
                    style={{ width: 335 }}
                    format="DD/MM/YYYY"
                    inputReadOnly
                    onChange={changeTime}
                    disabledDate={disabledDate}
                    suffixIcon={<></>}
                    separator={<div></div>}
                    placeholder={['dd/mm/yyyy', '']}
                />
            </div>
            <TableBox
                status={status}
                columns={columns}
                data={tableData}
                rowKey={val => val.workoutRewardsId}
                height={false}
                emptyText={{
                    emptyText:
                        (state.search && !isSearch) ||
                        state.startTime ||
                        state.endTime ||
                        (isSearch && !state.search) ? (
                            <div className="table_emptyBox">
                                Sorry, we were unable to find any results based on your conditions.
                            </div>
                        ) : (
                            ''
                        ),
                }}
                className="is_clickable r-table table_main"
                rowClassName={() => 'r-table_row'}
            />
            {status === 'showData' && tableData.length && totalRecord > 10 ? (
                <Pagination total={totalRecord} pageChange={handlePageChange} pageNo={state.pageNo} />
            ) : (
                <></>
            )}
        </div>
    );
};
export default Subscription;
