import { Button, Input, Modal } from 'antd';
import './style/index.scss';
import { useNavigate } from 'react-router-dom';
import closeIcon from '@/assets/images/Icons_Cross.png';
import { useState } from 'react';
import JSEncrypt from 'jsencrypt';
import { useSelector } from 'react-redux';
import api from '@/api/settings';
const Setting = (props: any) => {
    const userInfo = useSelector((state: any) => state.userInfo);
    const encrypt = new JSEncrypt();
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [state, setState] = useState({
        password: '',
        newPassword: '',
        reNewPassword: '',
    });
    const hideModal = () => {
        setIsModalOpen(false);
        setState({
            ...state,
            newPassword: '',
            reNewPassword: '',
        });
    };
    const [loading, setLoading] = useState(false);
    const confirmClick = () => {
        if (state.newPassword.length < 8) {
            window.format.alert('error', 'Please create a password of 8-16 characters');
            return;
        }
        if (state.newPassword === state.password) {
            window.format.alert('error', 'The current password is in use');
            return;
        }
        if (state.newPassword !== state.reNewPassword) {
            window.format.alert('error', 'The 2 passwords entered are not the same');
            return;
        }
        const key = window.sessionStorage.getItem('publicKey') ?? '';
        encrypt.setPublicKey(key);
        setLoading(true);
        const password = encrypt.encrypt(state.newPassword);
        const params = {
            password: password,
            email: userInfo.email,
            affirmPassword: password,
        };
        api.updatePassword(params, (res: any) => {
            if (res.code === 200) {
                window.format.alert('success', 'Password updated successfully');
                hideModal();
            } else {
                window.format.alert('error', res.message);
            }
            setLoading(false);
        });
    };
    return (
        <>
            <div className="setting_main page_mainBox">
                <h2>Settings</h2>
                <div
                    className="setting-item r-left"
                    onClick={() => {
                        setIsModalOpen(true);
                    }}
                >
                    Update Password
                </div>
                <div
                    className="setting-item r-left"
                    onClick={() => {
                        navigate('/setting/versions');
                    }}
                >
                    Version
                </div>
            </div>

            <Modal
                className="r-modal modal_no_padding"
                maskClosable={false}
                centered={true}
                open={isModalOpen}
                closeIcon={<img src={closeIcon} alt="" className="close-icon" />}
                onCancel={hideModal}
                footer={''}
                key={1}
                style={{ width: 542 }}
                title={[
                    <div className="modal-top-title" style={{ marginBottom: 40 }}>
                        Update Password
                    </div>,
                ]}
            >
                <div className="modal_form">
                    <p className="form_tip">Create new password</p>
                    <Input.Password
                        placeholder=""
                        className="r-input r-input_password"
                        maxLength={16}
                        value={state.newPassword}
                        onInput={(e: any) => setState({ ...state, newPassword: e.target.value })}
                    />
                    <p className="form_tip">Re-type Password</p>
                    <Input.Password
                        type="password"
                        className="r-input r-input_password"
                        maxLength={16}
                        value={state.reNewPassword}
                        onInput={(e: any) => setState({ ...state, reNewPassword: e.target.value })}
                    />

                    <div style={{ marginTop: 50 }}>
                        <Button
                            className={`r-primary_btn ${!state.newPassword || !state.reNewPassword ? 'disabled' : ''}`}
                            type="primary"
                            style={{ width: '100%', marginBottom: 20 }}
                            loading={loading}
                            onClick={() => confirmClick()}
                        >
                            Confirm
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default Setting;
