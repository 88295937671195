import React from 'react';
import '../style/index.scss';
const Information = props => {
    return (
        <div className="profile_box">
            <div className="r-top info_box">
                <p className="info_tit">Project ID: </p>
                <p className="info_msg">{props.projectInfo.projectId ?? '-'}</p>
            </div>
            <div className="r-top info_box">
                <p className="info_tit">Project Number: </p>
                <p className="info_msg">{props.projectInfo.projectNo ?? '-'}</p>
            </div>
            <div className="r-top info_box">
                <p className="info_tit">User Admins Name: </p>
                <p className="info_msg">{props.projectInfo.displayName ?? '-'} </p>
            </div>
            <div className="r-top info_box">
                <p className="info_tit">Businesss Entity Name: </p>
                <p className="info_msg">{props.projectInfo.businessName ?? '-'}</p>
            </div>
            <div className="r-top info_box">
                <p className="info_tit">Client Name: </p>
                <p className="info_msg">{props.projectInfo.clientName ?? '-'}</p>
            </div>
            <div className="r-top info_box">
                <p className="info_tit">Site Address: </p>
                <p className="info_msg">{props.projectInfo.siteAddress ?? '-'}</p>
            </div>
            <div className="r-top info_box">
                <p className="info_tit">Description: </p>
                <p className="info_msg">{props.projectInfo.description ?? '-'}</p>
            </div>
            <div className="r-top info_box">
                <p className="info_tit">Contract Category: </p>
                <p className="info_msg">{props.projectInfo.contractCategory ?? '-'}</p>
            </div>
            <div className="r-top info_box">
                <p className="info_tit">Contract Status: </p>
                <p className="info_msg">
                    {props.projectInfo.contractStatus === 3
                        ? 'Attached'
                        : props.projectInfo.contractStatus === 2
                        ? 'Attached'
                        : props.projectInfo.contractStatus === 1
                        ? 'Not Available'
                        : '-'}
                    <span className="mini_tips">
                        {props.projectInfo.contractStatus === 2
                            ? ' (Unsigned)'
                            : props.projectInfo.contractStatus === 3
                            ? ' (Signed)'
                            : ''}{' '}
                    </span>
                </p>
            </div>
        </div>
    );
};

export default Information;
