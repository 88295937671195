import React, { useEffect, useState } from 'react';
import '../style/index.scss';
import TableBox from '@/components/tableBox';
import closeIcon from '@/assets/images/Icons_Cross.png';
import { Button, Input, Modal, Select } from 'antd';

const ProjectValue = props => {
    useEffect(() => {
        if (props.projectInfo) {
            setState({
                projectValueList: [
                    createCostItem('Time', 'budgetWorkingHours', 'actualWorkingHours'),
                    createCostItem('Items and Materials', 'budgetMaterialsCosts', 'actualMaterialsCosts', true),
                    createCostItem('Machinery', 'budgetMachineryCost', 'actualMachineryCost', true),
                    createCostItem('Total', 'totalBudget', 'totalActualCost', true),
                ],
            });
        }
    }, []);
    const createCostItem = (title, budgetedCostKey, actualCostKey, isMoney = false) => {
        const budgetedCost = +props.projectInfo[budgetedCostKey];
        const actualCost = +props.projectInfo[actualCostKey];
        const costItem = {
            title,
            budgetedCost,
            actualCost,
            budgetedCostShow: isMoney ? `£${window.format.formatMoney(budgetedCost)}` : `${budgetedCost}h`,
            actualCostShow: isMoney ? `£${window.format.formatMoney(actualCost)}` : `${actualCost}h`,
            budgetedCostShowM: !isMoney ? `£${window.format.formatMoney(+props.projectInfo.budgetTotalSalary)}` : `0`,
            actualCostShowM: !isMoney ? `£${window.format.formatMoney(+props.projectInfo.actualTotalSalary)}` : `0`,
            onePreW: 480 / budgetedCost,
        };

        return costItem;
    };
    const [state, setState] = useState({
        projectValueList: [
            {
                title: 'Time',
                budgetedCost: 0,
                actualCost: 0,
                budgetedCostShow: `0h`,
                actualCostShow: `0h`,
                budgetedCostShowM: `£0.00`,
                actualCostShowM: `£0.00`,
                onePreW: 0,
            },
            {
                title: 'Items and Materials',
                budgetedCost: 0,
                actualCost: 0,
                budgetedCostShow: '£0.00',
                actualCostShow: '£0.00',
                onePreW: 0,
            },
            {
                title: 'Machinery',
                budgetedCost: 0,
                actualCost: 0,
                budgetedCostShow: '£0.00',
                actualCostShow: '£0.00',
                onePreW: 0,
            },
            {
                title: 'Total',
                budgetedCost: 0,
                actualCost: 0,
                budgetedCostShow: '£0.00',
                actualCostShow: '£0.00',
                onePreW: 0,
            },
        ],
    });
    return (
        <div className="project_valueBox">
            <div className="r-left">
                <div className="top_tit r-left">
                    Budgeted Costs:<span className="showBox_color"></span>
                </div>
                <div className="top_tit r-left mar50">
                    Actual Costs: <span className="showBox_color active"></span>
                </div>
            </div>
            <section className="project_valueLine">
                {state.projectValueList.map(item => {
                    return (
                        <>
                            <div className="titBox">{item.title}</div>
                            <div className="r-left">
                                <div className="budget_line"></div>
                                <div className="r-left numBox" style={{ marginBottom: 8 }}>
                                    {item.title === 'Time' ? (
                                        <>
                                            {item.budgetedCostShowM}
                                            {/* <span className="mini_num">({item.budgetedCostShow})</span> */}
                                        </>
                                    ) : (
                                        item.budgetedCostShow
                                    )}
                                </div>
                            </div>
                            <div className="r-left">
                                <div
                                    className="actual_line"
                                    style={{
                                        width: item.onePreW * item.actualCost + 'px',
                                        minWidth: item.onePreW * item.actualCost > 0 ? '1px' : '0',
                                    }}
                                ></div>
                                <div className="r-left numBox" style={{ marginLeft: item.actualCost ? 20 : 0 }}>
                                    {item.title === 'Time' ? (
                                        <>
                                            {item.actualCostShowM}
                                            <span className="mini_num">({item.actualCostShow})</span>
                                        </>
                                    ) : (
                                        item.actualCostShow
                                    )}
                                </div>
                            </div>
                        </>
                    );
                })}
            </section>
        </div>
    );
};

export default ProjectValue;
