import { Button, Modal, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import Profile from './components/profile';
import Team from './components/team';
import Project from './components/Project';
import Subscription from './components/Subscription';
import Pagination from '@/components/pagination';
import closeIcon from '@/assets/images/Icons_Cross.png';
import { useNavigate } from 'react-router-dom';
import api from '@/api/user';
import { defaultInfo } from '@/typings/allType';
import { useAliveController } from 'react-activation';
import { getCompanyTypeText } from '@/typings/apiType';
import { LoadingOutlined } from '@ant-design/icons';
const UserDetail = props => {
    const navigate = useNavigate();
    const { drop } = useAliveController();
    useEffect(() => {
        const path = props.prevLocation?.pathname;
        if (path === '/project-detail') {
            getList({ userId: state.userInfo.userId, pageSize: 10, pageNum: pageNum, type: activeTab });
        }
    }, [props.prevLocation]);
    const back = () => {
        drop('user-detail');
        setTimeout(() => {
            // navigate('/user');
            navigate(-1);
        }, 100);
    };
    useEffect(() => {
        const userInfo = JSON.parse(window.sessionStorage.getItem('userInfo') ?? '{}');
        if (userInfo.userId) {
            setStatusFather('loading');
            api.getUserDetail({ userId: userInfo.userId }, res => {
                setStatusFather('showData');
                const { code, message, data } = res;
                if (code === 200) {
                    data.avatarUrl = data.avatarUrl && data.avatarUrl !== null ? data.avatarUrl : '';
                    data.businessTypes = data.businessTypes ? getCompanyTypeText(data.businessTypes) : '';
                    data.createTime = data.createTime ? window.format.foramtTimeDMY_easy(+data.createTime) : '-';
                    data.mobileNumber = data.mobileNumber ? window.format.formatNumber(data.mobileNumber) : '-';
                    data.subscriptionAmount = data.subscriptionAmount
                        ? window.format.formatMoney(data.subscriptionAmount)
                        : '0.00';
                    data.subscriptionExpiryTime =
                        data.subscriptionExpiryTime !== null && data.subscriptionExpiryTime
                            ? window.format.foramtTimeDMY_easy(+data.subscriptionExpiryTime)
                            : '';
                    setState({ ...state, userInfo, isSuspended: data.disabled });
                    setUserDetail(data);
                } else {
                    window.format.alert('error', message);
                }
            });
        }
    }, []);
    const [state, setState] = useState({
        isSuspended: false,
        pageNo: 1,
        userInfo: {} as defaultInfo,
        teamNum: 0,
        subNum: 0,
        projectNum: { active: 0, pending: 0, completed: 0 },
    });
    const [pageNum, setPageNum] = useState(1);
    const [userDetail, setUserDetail] = useState({
        avatarUrl: '',
        email: '',
        jobTitle: '',
        businessTypes: '',
        mobileNumber: '',
        subscriptionExpiryTime: '',
        createTime: '',
        businessName: '',
        displayName: '',
    });
    const [status, setStatus] = useState('showData');
    const [statusFather, setStatusFather] = useState('showData');
    const [tableData, setTableData] = useState([]);
    const [totalRecord, setTotalRecord] = useState(0);
    const [activeTab, setActiveTab] = useState(1);
    const userTabList = [
        { label: 'Profile', key: 1 },
        { label: 'Team', key: 2 },
        { label: 'Projects', key: 3 },
        { label: 'Subscription', key: 4 },
    ];
    const getList = params => {
        params = Object.assign(params, {
            userId: state.userInfo.userId,
            pageSize: 10,
            pageNum: params.pageNum ?? pageNum,
            type: params.type ?? activeTab,
        });
        setStatus('loading');
        if (params.type === 2) {
            api.getTeamList({ ...params }, res => callback(res, params.type));
        } else if (params.type === 3) {
            api.getUserProjectList({ ...params }, res => callback(res, params.type));
        } else if (params.type === 4) {
            api.getUserSubscriptionList({ ...params }, res => callback(res, params.type));
        }
    };
    const callback = (res, type) => {
        const { code, message, data } = res;
        if (code === 200) {
            if (data.records.length > 0) {
                const list = data.records.map(item => {
                    const statusMap = {
                        3: { color: '#097dff', text: 'Active' },
                        2: { color: '#FFA800', text: 'Pending' },
                        4: { color: '#82C74F', text: 'Completed' },
                    };
                    const { color = '', text = '-' } = statusMap[item?.projectStatus] || {};
                    return Object.assign(item, {
                        rate: item.rate ? window.format.formatMoney(+item.rate) : '0.00',
                        expiresDate:
                            item.expiresDate && item.expiresDate !== null
                                ? window.format.foramtTimeDMY_easy(+item.expiresDate)
                                : 'Free',
                        subscriptionPrice: item.subscriptionPrice
                            ? window.format.formatMoney(+item.subscriptionPrice)
                            : '0.00',
                        startDateMs:
                            item.startDateMs && item.startDateMs !== null
                                ? window.format.foramtTimeDMY_easy(+item.startDateMs)
                                : '-',
                        startDate:
                            item.startDate && item.startDate !== null
                                ? window.format.foramtTimeDMY_easy(+item.startDate)
                                : '-',
                        completionDate:
                            item.completionDate && item.completionDate !== null
                                ? window.format.foramtTimeDMY_easy(+item.completionDate)
                                : '-',
                        color,
                        text,
                    });
                });
                setState({
                    ...state,
                    teamNum: type === 2 ? data.metaData : 0,
                    subNum: type === 4 ? data.metaData : 0,
                    projectNum: type === 3 ? data.metaData : state.projectNum,
                });
                setTableData(list);
                setStatus('showData');
                setTotalRecord(data.total);
            } else {
                setStatus('showData');
                setTableData([]);
                setTotalRecord(data.total);
            }
        } else {
            setStatus('empty');
            window.format.alert('error', message);
        }
    };
    const changeTabActive = item => {
        setActiveTab(item.key);
        if (item.key !== 1) {
            getList({ userId: state.userInfo.userId, pageSize: 10, pageNum: 1, type: item.key });
        }
    };
    const handlePageChange = prop => {
        setPageNum(prop);
        if (activeTab !== 1) {
            getList({ userId: state.userInfo.userId, pageSize: 10, pageNum: prop, type: activeTab });
        }
    };
    const [opeationModalOpen, setOpeationModalOpen] = useState(false);
    const [opeationType, setOpeationType] = useState(1); // 1 del 2 suspend
    const opeationBtnClcik = (type: number) => {
        setOpeationType(type);
        if (type === 2 && state.isSuspended) {
            // 启用
            api.disableUser({ userId: state.userInfo.userId, status: false }, res => {
                const { code, message, data } = res;
                if (code === 200) {
                    setState({ ...state, isSuspended: false });
                } else {
                    window.format.alert('error', message);
                }
            });
            return;
        }
        setOpeationModalOpen(true);
    };
    const [loading, setLoading] = useState(false);
    const confirmOpeationClick = () => {
        setLoading(true);
        if (opeationType === 1) {
            api.deleteUser({ userId: state.userInfo.userId }, res => {
                const { code, message, data } = res;
                if (code === 200) {
                    window.format.alert('success', message);
                    setLoading(false);
                    setOpeationModalOpen(false);
                    navigate(-1);
                } else {
                    setLoading(false);
                    setOpeationModalOpen(false);
                    window.format.alert('error', message);
                }
            });
        } else {
            api.disableUser({ userId: state.userInfo.userId, status: true }, res => {
                const { code, message, data } = res;
                if (code === 200) {
                    setState({ ...state, isSuspended: true });
                    setLoading(false);
                    setOpeationModalOpen(false);
                } else {
                    setLoading(false);
                    setOpeationModalOpen(false);
                    window.format.alert('error', message);
                }
            });
        }
    };
    return (
        <>
            {statusFather === 'loading' ? (
                <div className="data_loading flex-center" style={{ height: 'auto' }}>
                    {/* <Spin  /> */}
                    <Spin />
                </div>
            ) : (
                <></>
            )}
            {statusFather === 'showData' ? (
                <div className="user_detailMain page_mainBox">
                    <div className="r-left">
                        <div className="back_box r-left" onClick={back}>
                            <i className="back_icon"></i>
                            Back
                        </div>
                        <Button
                            className="r-dergerDefault_btn marLeft"
                            onClick={() => opeationBtnClcik(1)}
                            style={{ marginRight: 10 }}
                        >
                            Remove
                        </Button>
                        {state.isSuspended ? (
                            <Button className="r-success_btn" onClick={() => opeationBtnClcik(2)}>
                                Active
                            </Button>
                        ) : (
                            <Button className="r-derger_btn" onClick={() => opeationBtnClcik(2)}>
                                Suspend
                            </Button>
                        )}
                    </div>
                    <section className="user_info">
                        <div className="r-left userInfo_top">
                            <img
                                src={
                                    userDetail.avatarUrl
                                        ? userDetail.avatarUrl
                                        : require('@/assets/images/user-icon.png')
                                }
                                className="userImg"
                                alt=""
                            />
                            <div style={{ marginLeft: 20, paddingRight: 100 }}>
                                <p className="user_name">{userDetail.businessName ?? '-'}</p>
                                <p className="user_tips">{userDetail.displayName ?? '-'}</p>
                            </div>
                            <div className={`user_statusBox r-center marLeft ${state.isSuspended ? 'suspended' : ''}`}>
                                {!state.isSuspended ? 'Active' : 'Suspend'}
                            </div>
                        </div>
                        <ul className="r-left" style={{ margin: 0 }}>
                            {userTabList.map((item, index) => (
                                <li
                                    key={index}
                                    onClick={() => changeTabActive(item)}
                                    className={`user_tabItem ${activeTab === item.key ? 'active' : ''}`}
                                >
                                    {item.label}
                                </li>
                            ))}
                        </ul>
                    </section>
                    <div>
                        {activeTab === 1 ? (
                            <Profile userDetail={userDetail}></Profile>
                        ) : activeTab === 2 ? (
                            <Team
                                tableData={tableData}
                                status={status}
                                teamNum={state.teamNum}
                                getList={() => getList({})}
                            ></Team>
                        ) : activeTab === 3 ? (
                            <Project tableData={tableData} status={status} projectNum={state.projectNum}></Project>
                        ) : (
                            <Subscription
                                tableData={tableData}
                                status={status}
                                userDetail={userDetail}
                                subNum={state.subNum}
                            ></Subscription>
                        )}
                        {status === 'showData' && activeTab !== 1 && tableData.length && totalRecord > 10 ? (
                            <Pagination total={totalRecord} pageChange={handlePageChange} pageNo={pageNum} />
                        ) : (
                            <></>
                        )}
                    </div>

                    {/* del or suspend  modal*/}
                    <Modal
                        className="r-modal modal_no_padding"
                        maskClosable={false}
                        centered={true}
                        open={opeationModalOpen}
                        closeIcon={<img src={closeIcon} alt="" className="close-icon" />}
                        onCancel={() => setOpeationModalOpen(false)}
                        footer={''}
                        key={1}
                        style={{ width: 542 }}
                        title={[
                            <div className="modal-top-title" style={{ marginBottom: 40 }}>
                                {opeationType === 1 ? 'Remove' : 'Suspend'}
                            </div>,
                        ]}
                    >
                        <div className="modal_form">
                            <p className="modal_tips">
                                {opeationType === 1
                                    ? 'This will delete all data associated with this account. are you sure you want to continue?'
                                    : 'The user will not be able to log in to the APP. are you sure you want to continue?'}
                            </p>
                            <div className="r-left" style={{ marginTop: 50 }}>
                                <Button
                                    className="r-defaultBlick_btn"
                                    type="primary"
                                    style={{ width: '100%', margin: '0 20px 20px 0' }}
                                    onClick={() => setOpeationModalOpen(false)}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className="r-dergerMedium_btn"
                                    type="primary"
                                    style={{ width: '100%', marginBottom: 20 }}
                                    loading={loading}
                                    onClick={() => confirmOpeationClick()}
                                >
                                    Confirm
                                </Button>
                            </div>
                        </div>
                    </Modal>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export default UserDetail;
