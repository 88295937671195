import React, { useState } from 'react';
import '../style/index.scss';
import TableBox from '@/components/tableBox';
import Pagination from '@/components/pagination';
import { getCompanyTypeText, getUserPositionTypeText } from '@/typings/apiType';
const KeyPersonnelDetails = props => {
    const columns = [
        {
            title: 'Name',
            key: 'firstName',
            width: 350,
            render: val => (
                <div className="cell-txt" style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                    {val.firstName ?? '-'} {val.lastName}
                </div>
            ),
        },
        {
            title: 'Email',
            dataIndex: '',
            key: 'email',
            width: '18%',
            render: val => <div className="cell-txt">{val.email ?? '-'}</div>,
        },
        {
            title: 'Job Title',
            dataIndex: '',
            key: 'jobTitle',
            width: '15%',
            render: val => <div className="cell-txt">{val.jobTitle ? getUserPositionTypeText(val.jobTitle) : '-'}</div>,
        },
        {
            title: 'Authorised Personnel',
            dataIndex: '',
            key: 'authorisedPersonnel',
            width: 250,
            render: val => <div className="cell-txt">{val.authorisedPersonnel ? 'Yes' : 'No'}</div>,
        },
        {
            title: 'Rate',
            dataIndex: '',
            key: 'rate',
            width: '10%',
            render: val => <div className="cell-txt">£{val.rate ? window.format.formatMoney(val.rate) : '0.00'}</div>,
        },
        {
            title: 'Start Date',
            dataIndex: '',
            key: 'startDate',
            width: 150,
            render: val => (
                <div className="cell-txt">
                    {val.startDate !== null ? window.format.foramtTimeDMY_easy(+val.startDate) : '-'}
                </div>
            ),
        },
    ];
    const handlePageChange = (type, index, page) => {
        props.handlePageChange(type, index, page);
    };
    return (
        <div className="keyPersion_box">
            <div className="internal_list">
                <h3>Internal: {props.projectInfo.internalUserCount ?? 0}</h3>
                <TableBox
                    status="showData"
                    columns={columns}
                    data={props.tableData}
                    emptyText={{
                        emptyText: (
                            <div className="table_emptyBox" style={{ margin: '20px 0' }}>
                                No Data
                            </div>
                        ),
                    }}
                    rowKey={val => val.contractingPartyId}
                    height={false}
                    className="is_clickable r-table table_main"
                    rowClassName={() => 'r-table_row'}
                />
                {props.tableData.length && props.internalInfo.total > 10 ? (
                    <Pagination
                        total={props.internalInfo.total}
                        pageChange={page => handlePageChange(0, 0, page)}
                        pageNo={props.internalInfo.currentPage}
                    />
                ) : (
                    <></>
                )}
            </div>
            {props.exterbalTableData.length ? (
                <div className="internal_list" style={{ marginTop: 40 }}>
                    <h3 style={{ margin: 0 }}>External: {props.projectInfo.externalUserCount ?? 0}</h3>
                    {props.exterbalTableData.map((item, i) => {
                        return (
                            <>
                                <div className="r-left start_top" style={{ margin: '20px 0 15px 0' }}>
                                    <h4>Business Entity Name: {item.businessName ?? '-'}</h4>
                                    <h4 style={{ marginLeft: 50 }}>
                                        Business Entity Type:{' '}
                                        {item.businessTypes ? getCompanyTypeText(item.businessTypes) : '-'}
                                    </h4>
                                </div>
                                <TableBox
                                    status="showData"
                                    columns={columns}
                                    data={item.projectTeamVosShow}
                                    rowKey={val => val.contractingPartyId}
                                    height={false}
                                    className="is_clickable r-table table_main"
                                    rowClassName={() => 'r-table_row'}
                                    emptyText={{
                                        emptyText: (
                                            <div className="table_emptyBox" style={{ margin: '20px 0' }}>
                                                No Data
                                            </div>
                                        ),
                                    }}
                                />
                                {item.projectTeamVos.length && item.total > 10 ? (
                                    <Pagination
                                        total={item.total}
                                        pageChange={page => handlePageChange(1, i, page)}
                                        pageNo={item.currentPage}
                                    />
                                ) : (
                                    <></>
                                )}
                            </>
                        );
                    })}
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default KeyPersonnelDetails;
