import React, { useCallback, useEffect, useState } from 'react';
import '../style/index.scss';
import type { MenuProps } from 'antd';
import closeIcon from '@/assets/images/Icons_Cross.png';
import { debounce } from 'lodash';
import api from '@/api/project';
import { Affix, Input, Dropdown, Modal, Button, Spin, Image } from 'antd';
import { defaultInfo } from '@/typings/allType';
import { LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';

const Folders = props => {
    const getFolderList = (keyword?: string, folderId?: string) => {
        const params = {
            projectId: props.projectInfo.projectId,
            keyword,
            folderId,
        };
        setStatus('loading');
        api.getProjectFolderList(params, res => {
            const { code, message, data } = res;
            setStatus('showData');
            if (code === 200) {
                if (!data.length) return;
                setState({
                    ...state,
                    folders: data.map((item: defaultInfo, i: number) => {
                        return Object.assign(item, { isChoose: i === selectIndex ? true : false });
                    }),
                });
                // setSelectFolder(selectIndex ? data[selectIndex] : data[0]);
                // chooseFolder(selectIndex, data[selectIndex]);
                setSelectFolder({
                    ...data[selectIndex],
                    fileInfoVos: data[selectIndex].fileInfoVos.map(item => {
                        const url = item.fileUrl && item.fileUrl !== null ? item.fileUrl : '';
                        return Object.assign(item, {
                            showUrl: url,
                            size: window.format.getFileSize(url) ?? '0 kb',
                            type: window.format.getFileExtensionName(url) ?? '-',
                            fileType: window.format.getFileExtensionNameDownload(url) ?? '',
                            createTime: window.format.getFileCreateTime(url) ?? '-',
                        });
                    }),
                });
            } else {
                window.format.alert('error', message);
            }
        });
    };
    useEffect(() => {
        if (props.projectInfo) {
            getFolderList();
        }
    }, []);
    const [state, setState] = useState({
        search: '',
        opeationInfo: { fileId: '', projectsFileId: '' },
        folders: [] as any[],
    });
    const [status, setStatus] = useState('loading');
    const [isLoading, setIsLoading] = useState(false);
    const [selectIndex, setSelectFolderIndex] = useState(0);
    const [selectFolder, setSelectFolder] = useState({
        isChoose: true,
        name: '-',
        fileInfoVos: [] as any[],
    });
    const chooseFolder = (index: number, val: any) => {
        setSelectFolderIndex(index);
        setIsLoading(false);
        setState({
            ...state,
            search: '',
            folders: state.folders.map((item, i) => {
                return Object.assign(item, { isChoose: i === index });
            }),
        });
        setSelectFolder({
            ...val,
            fileInfoVos: val.fileInfoVos.map(item => {
                const url = item.fileUrl && item.fileUrl !== null ? item.fileUrl : '';
                return Object.assign(item, {
                    showUrl: url,
                    size: window.format.getFileSize(url) ?? '0 kb',
                    type: window.format.getFileExtensionName(url) ?? '-',
                    fileType: window.format.getFileExtensionNameDownload(url) ?? '',
                    createTime: window.format.getFileCreateTime(url) ?? '-',
                });
            }),
        });
    };
    const [searchListArr, setSearchListArr] = useState<any[]>([]);
    const searchList: any = debounce(params => {
        // console.log('====================================');
        console.log(selectFolder.fileInfoVos);
        // console.log('====================================');
        const list = selectFolder.fileInfoVos.filter((item: any) => {
            return item.fileName.toLocaleLowerCase().indexOf(params.search.toLocaleLowerCase()) > -1;
        });
        console.log('====================================');
        console.log(list);
        console.log('====================================');
        setSearchListArr(list);
        setIsLoading(false);
    }, 1000);
    const [spinning, setSpinning] = React.useState<boolean>(false);
    const downloadFile = (url: any, name: string, fileType: string) => {
        setSpinning(true);
        try {
            const token = sessionStorage.getItem('admin-satoken') ?? '';
            axios
                .get(`${process.env.REACT_APP_URL}/folder-entity/download`, {
                    params: { url },
                    responseType: 'blob',
                    headers: { 'coldap-Admin-satoken': token },
                })
                .then(res => {
                    const newName = window.format.getFileNewName(url);
                    const blob = new Blob([res.data], { type: fileType });
                    const downloadElement = document.createElement('a');
                    const href = window.URL.createObjectURL(blob);
                    downloadElement.href = href;
                    downloadElement.download = name.length < 20 ? name : newName;
                    document.body.appendChild(downloadElement);
                    downloadElement.click();
                    document.body.removeChild(downloadElement);
                    setSpinning(false);
                })
                .catch(err => {
                    setSpinning(false);
                    window.format.alert('error', 'Download failed, please try again later!');
                });
        } catch (error) {
            setSpinning(false);
            window.format.alert('error', 'Download failed, please try again later!');
        }
    };
    const onClick: any = ({ key }, item) => {
        if (key === '1') {
            //download
            if (!item.fileUrl) {
                window.format.alert('error', 'Invalid file address.');
                return;
            }
            downloadFile(item.showUrl, item.fileName, item.fileType);
            return;
        }
        setState({
            ...state,
            opeationInfo: item,
        });
        setOpeationInfoModalOpen(true);
    };
    const items: MenuProps['items'] = [
        {
            label: 'Download',
            key: '1',
        },
        {
            label: 'Remove',
            key: '2',
        },
    ];
    const [opeationModalOpen, setOpeationInfoModalOpen] = useState(false);
    const confirmOpeationClick = () => {
        api.deleteProjectFolder({ fileId: state.opeationInfo.projectsFileId }, res => {
            const { code, message, data } = res;
            if (code === 200) {
                setOpeationInfoModalOpen(false);
                getFolderList(state.search ? state.search : '');
            } else {
                window.format.alert('error', message);
            }
        });
    };
    return (
        <>
            <Spin spinning={spinning} delay={500} fullscreen />
            {status === 'loading' ? (
                <div className="data_loading flex-center" style={{ height: props.height ? props.height : 'auto' }}>
                    <Spin />
                </div>
            ) : status === 'showData' && state.folders.length ? (
                <div className="folders_box r-left">
                    <div className="leftFolder_box">
                        <div className="search_file r-left">
                            <div>
                                <p className="folder_name">{selectFolder.name}</p>
                                <p className="fileNumber">
                                    {selectFolder.fileInfoVos.length}{' '}
                                    {selectFolder.fileInfoVos.length > 1 ? 'Files' : 'File'}
                                </p>
                            </div>
                            <Input
                                placeholder="Filename "
                                style={{ width: 335 }}
                                value={state.search}
                                allowClear
                                onChange={e => {
                                    setIsLoading(true);
                                    setState({ ...state, search: e.target.value });
                                    searchList({ ...state, search: e.target.value });
                                }}
                                className="r-input r-input_serach marLeft"
                                prefix={<span className="search_icon"></span>}
                            />
                        </div>
                        <div className="fileBox_new">
                            {isLoading ? (
                                <div
                                    className="data_loading flex-center"
                                    style={{ height: props.height ? props.height : 'auto' }}
                                >
                                    <Spin />
                                </div>
                            ) : (!state.search && selectFolder.fileInfoVos.length) ||
                              (state.search && searchListArr.length && !isLoading) ? (
                                <div className="r-left fileList_box">
                                    {(state.search ? searchListArr : selectFolder.fileInfoVos).map((item, index) => {
                                        return (
                                            <div className="file_item r-left" key={index}>
                                                <div className="file_icon">
                                                    {/* <img
                                                        className="file_iconImg"
                                                        src={
                                                            item.type === 'image'
                                                                ? item.showUrl
                                                                : require('@/assets/images/Icon-file.png')
                                                        }
                                                        alt=""
                                                    /> */}
                                                    <Image
                                                        className="file_iconImg"
                                                        preview={false}
                                                        width={47}
                                                        height={47}
                                                        src={
                                                            item.type === 'image'
                                                                ? item.showUrl
                                                                : require('@/assets/images/Icon-file.png')
                                                        }
                                                        fallback={require('@/assets/images/user-icon.png')}
                                                    />
                                                </div>
                                                <div className="file_info">
                                                    <div className="file_name oneLine_text">{item.fileName ?? '-'}</div>
                                                    <div className="file_type" style={{ margin: '10px 0 2px 0' }}>
                                                        {item.size} / {item.type}
                                                    </div>
                                                    <div className="file_type">{item.createTime}</div>
                                                </div>
                                                <div className="marLeft">
                                                    <Dropdown
                                                        menu={{
                                                            items,
                                                            onClick: val => {
                                                                onClick(val, item);
                                                            },
                                                        }}
                                                        placement="bottomRight"
                                                    >
                                                        <div className="r-left">
                                                            <i className="file_more"></i>
                                                        </div>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : (
                                <div className="table_emptyBox">No Data</div>
                            )}
                        </div>
                    </div>
                    <div className="rightFolder_box">
                        <h3>
                            {state.folders.length <= 1 ? 'Folder' : 'Folders'}: {state.folders.length ?? 0}
                        </h3>
                        {state.folders.length ? (
                            <div className="folders_boxNew">
                                {state.folders.map((item, index) => {
                                    return (
                                        <div
                                            className={`folder_item r-left ${item.isChoose ? 'active' : ''}`}
                                            key={index}
                                            onClick={() => chooseFolder(index, item)}
                                        >
                                            <i className={`folder_icon`}></i>
                                            <div className="folder_name twoLine_text">{item.name}</div>
                                        </div>
                                    );
                                })}
                            </div>
                        ) : (
                            <>
                                <div className="table_emptyBox">No Data</div>
                            </>
                        )}
                    </div>
                </div>
            ) : (
                <div className="table_emptyBox">No data</div>
            )}

            <Modal
                className="r-modal modal_no_padding"
                maskClosable={false}
                centered={true}
                open={opeationModalOpen}
                closeIcon={<img src={closeIcon} alt="" className="close-icon" />}
                onCancel={() => setOpeationInfoModalOpen(false)}
                footer={''}
                key={1}
                style={{ width: 542 }}
                title={[
                    <div className="modal-top-title" style={{ marginBottom: 40 }}>
                        Remove
                    </div>,
                ]}
            >
                <div className="modal_form">
                    <p className="modal_tips">Are you sure you want to delete this file?</p>
                    <div className="r-left" style={{ marginTop: 50 }}>
                        <Button
                            className="r-defaultBlick_btn"
                            type="primary"
                            style={{ width: '100%', margin: '0 20px 20px 0' }}
                            onClick={() => setOpeationInfoModalOpen(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="r-dergerMedium_btn"
                            type="primary"
                            style={{ width: '100%', marginBottom: 20 }}
                            onClick={() => confirmOpeationClick()}
                        >
                            Confirm
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default Folders;
