import React, { useState, useEffect } from 'react';
import { Layout, Button, Modal, Spin, message } from 'antd';
import loginApi from '@/api/login';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import closeIcon from '@/assets/images/Icons_Cross.png';
import logoIcon from '@/assets/images/logo.png';
import './index.scss';
import { useSelector } from 'react-redux';
import KeepAlive, { useAliveController } from 'react-activation';
import usePrevious from '@/hook/usePrevious';
const { Content } = Layout;
const LayoutMain = props => {
    const { clear } = useAliveController();
    const commonplace = useSelector((state: any) => state.commonplace);
    const location = useLocation();
    useEffect(() => {
        filterRoute();
    }, [location]);
    const filterRoute = () => {
        const currentPath = props.routes.filter(i => {
            return location.pathname.includes(i.path) && i.isShowOnNav;
        });
        if (currentPath[0] && currentPath[0].path) setCurrent(currentPath[0].name);
    };

    const prevLocation: any = usePrevious(location);
    const navigate = useNavigate();
    const [current, setCurrent] = useState('Statistics');
    const checkNav = e => {
        // clear cache component
        console.log(e.name);
        clear();
        setCurrent(e.name);
        navigate(e.path);
    };
    const logOut = () => {
        loginApi.logOut(res => {
            if (res.code === 200) {
                setIsModalOpen(false);
                sessionStorage.clear();
                navigate('/login');
            } else {
                message.error(res.message);
            }
        });
    };
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            <Spin spinning={commonplace.loading} tip={commonplace.text} wrapperClassName={commonplace.class}>
                <Layout className="layout-container">
                    <div className="header_nav">
                        <img src={logoIcon} className="logo" alt="" />
                        <div className="menu_items">
                            {props.routes.map((item, i) => {
                                return item.isShowOnNav ? (
                                    <div
                                        className={`menu_item r-left ${current === item.name ? 'active' : ''}`}
                                        key={i}
                                        onClick={() => checkNav(item)}
                                    >
                                        <i
                                            className="menu_icon"
                                            style={{
                                                background: `url(${require(`../assets/images/header-icon/${
                                                    current === item.name ? `${item?.icon}_ed` : item?.icon
                                                }.png`)})`,
                                            }}
                                        ></i>
                                        {item.name}
                                    </div>
                                ) : (
                                    ''
                                );
                            })}
                        </div>
                        <Button
                            onClick={() => {
                                setIsModalOpen(true);
                            }}
                            className="logout_btn"
                        >
                            Log Out
                        </Button>
                    </div>
                    <Layout className="layout-bottom">
                        <Layout style={{ padding: '0 0 0 0' }}>
                            <Content
                                style={{
                                    margin: 0,
                                    minHeight: 280,
                                }}
                            >
                                {/* content */}
                                <Routes>
                                    {props.routes.map((route: any, index: any) => {
                                        return route.KeepAlive ? (
                                            <Route
                                                path={route.path}
                                                key={index}
                                                element={
                                                    <KeepAlive
                                                        cacheKey={route.path}
                                                        saveScrollPosition="screen"
                                                        name={route.name}
                                                    >
                                                        <route.component
                                                            prevLocation={prevLocation}
                                                            routes={route.childrens ? route.childrens : []}
                                                        />
                                                    </KeepAlive>
                                                }
                                            ></Route>
                                        ) : (
                                            <Route
                                                path={route.path}
                                                key={index}
                                                element={
                                                    <route.component
                                                        prevLocation={prevLocation}
                                                        routes={route.childrens ? route.childrens : []}
                                                    />
                                                }
                                            ></Route>
                                        );
                                    })}
                                </Routes>
                            </Content>
                        </Layout>
                    </Layout>
                </Layout>

                <Modal
                    className="r-modal modal_no_padding"
                    maskClosable={false}
                    centered={true}
                    open={isModalOpen}
                    closeIcon={<img src={closeIcon} alt="" className="close-icon" />}
                    onCancel={() => setIsModalOpen(false)}
                    footer={''}
                    key={1}
                    style={{ width: 542 }}
                    title={[
                        <div className="modal-top-title" style={{ marginBottom: 40 }}>
                            Logout
                        </div>,
                    ]}
                >
                    <div className="modal_form">
                        <p className="modal_tips">Are you sure to log out of the current account?</p>
                        <div className="r-left" style={{ marginTop: 50 }}>
                            <Button
                                className="r-defaultBlick_btn"
                                type="primary"
                                style={{ width: '100%', margin: '0 20px 20px 0' }}
                                onClick={() => setIsModalOpen(false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                className="r-primary_btn"
                                type="primary"
                                style={{ width: '100%', marginBottom: 20 }}
                                onClick={() => logOut()}
                            >
                                Confirm logout
                            </Button>
                        </div>
                    </div>
                </Modal>
            </Spin>
        </>
    );
};

export default LayoutMain;
