import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, Button, Table, DatePicker, Modal } from 'antd';
import './style/index.scss';
import TableBox from '@/components/tableBox';
import Pagination from '@/components/pagination';
import closeIcon from '@/assets/images/Icons_Cross.png';
import api from '@/api/settings';
import { defaultInfo } from '@/typings/allType';
import TextArea from 'antd/es/input/TextArea';

const Versions = (props: any) => {
    const navigate = useNavigate();
    const back = () => {
        navigate(-1);
    };
    const [status, setStatus] = useState('showData');
    const [totalRecord, setTotalRecord] = useState(100);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [state, setState] = useState({
        pageNum: 1,
        updataDetail: '',
        version: '',
        minVersion: '',
    });
    const handlePageChange = prop => {
        setState({ ...state, pageNum: prop });
        getList({ ...state, pageNum: prop });
    };
    const getList = (val?: defaultInfo) => {
        if (status === 'loading') return;
        const params = {
            pageNum: val ? val.pageNum : state.pageNum,
            pageSize: 10,
            mobileTypes: 0,
        };
        setStatus('loading');
        api.getVersionList(params, res => callback(res));
    };
    const callback = (res: defaultInfo) => {
        const { data, code, message } = res;
        if (code === 200) {
            if (data.records.length > 0) {
                const list = data.records.map(item => {
                    return Object.assign(item, {
                        createTime: item.createTime ? window.format.foramtTimeDMYHM_easy(+item.createTime) : '-',
                    });
                });
                setTableData(list);
                setStatus('showData');
                setTotalRecord(data.total);
            } else {
                setStatus('empty');
                setTotalRecord(data.total);
            }
        } else {
            setStatus('empty');
            window.format.alert('error', message);
        }
    };
    useEffect(() => {
        getList();
    }, []);
    const [tableData, setTableData] = useState([]);
    const columns = [
        {
            title: 'Version',
            dataIndex: '',
            key: 'version',
            render: val => <div className="cell-txt r-left">V{val.version}</div>,
        },
        {
            title: 'Minimum Version',
            dataIndex: '',
            key: 'minimumVersion',
            render: val => <div className="cell-txt">V{val.minimumVersion}</div>,
        },
        {
            title: 'Upload Time',
            dataIndex: 'createTime',
            key: 'createTime',
            // render: val => <div className="cell-txt">15 Jun ‘22 12:40</div>,
        },
        {
            title: 'Upload Details',
            dataIndex: '',
            key: 'details',
            render: val => (
                <div className="cell-txt" style={{ wordBreak: 'break-all' }}>
                    {val.details ?? '-'}
                </div>
            ),
        },
    ];
    const hideModal = () => {
        setIsModalOpen(false);
        setState({
            ...state,
            updataDetail: '',
            version: '',
            minVersion: '',
        });
    };
    const [loading, setLoading] = useState(false);
    const confirmClick = () => {
        setLoading(true);
        const params = {
            version: state.version,
            minimumVersion: state.minVersion,
            details: state.updataDetail,
            mobileTypes: 0,
        };
        api.versionAdd(params, res => {
            const { data, code, message } = res;
            if (code === 200) {
                hideModal();
                getList();
            } else {
                window.format.alert('error', message);
            }
            setLoading(false);
        });
    };
    return (
        <>
            <div className="version_main table_page page_mainBox">
                <div className="r-left">
                    <div className="back_box r-left" onClick={back}>
                        <i className="back_icon"></i>
                        Back
                    </div>
                </div>
                <div className="r-left top_navBox">
                    <h2>Version </h2>
                    <Button className="r-primary_btn marLeft" onClick={() => setIsModalOpen(true)}>
                        Upload version details
                    </Button>
                </div>
                <div className="r-left tab_list">
                    <span className="tab_item">iOS APP</span>
                </div>
                <TableBox
                    status={status}
                    columns={columns}
                    data={tableData}
                    rowKey={val => val.workoutRewardsId}
                    height={false}
                    className="is_clickable r-table table_main"
                    rowClassName={() => 'r-table_row'}
                />
                {status === 'showData' && tableData.length > 10 ? (
                    <Pagination total={totalRecord} pageChange={handlePageChange} pageNo={state.pageNum} />
                ) : (
                    <></>
                )}
            </div>

            <Modal
                className="r-modal modal_no_padding"
                maskClosable={false}
                centered={true}
                open={isModalOpen}
                closeIcon={<img src={closeIcon} alt="" className="close-icon" />}
                onCancel={hideModal}
                footer={''}
                key={1}
                style={{ width: 542 }}
                title={[
                    <div className="modal-top-title" style={{ marginBottom: 40 }}>
                        Upload version details
                    </div>,
                ]}
            >
                <div className="modal_form">
                    <p className="form_tip">Mobile device</p>
                    <Input className="r-input" value="iOS" readOnly />
                    <p className="form_tip">Version</p>
                    <Input
                        className="r-input"
                        maxLength={20}
                        value={state.version}
                        onInput={(e: any) => {
                            e.target.value = e.target.value.replace(/[^\d^\.]/g, '');
                            setState({ ...state, version: e.target.value });
                        }}
                    />
                    <p className="form_tip">Minimum version</p>
                    <Input
                        className="r-input"
                        maxLength={20}
                        value={state.minVersion}
                        onInput={(e: any) => {
                            e.target.value = e.target.value.replace(/[^\d^\.]/g, '');
                            setState({ ...state, minVersion: e.target.value });
                        }}
                    />
                    <p className="form_tip">Upload details</p>
                    <TextArea
                        style={{ height: 200, resize: 'none' }}
                        className="r-textarea"
                        maxLength={4000}
                        showCount
                        value={state.updataDetail}
                        onChange={(e: any) => {
                            setState({ ...state, updataDetail: e.target.value });
                        }}
                    />
                    {/* <Input
                        className="r-input"
                        suffix={<span style={{ color: '#A3A3A3' }}>£/h</span>}
                        onInput={(e: any) => setState({ ...state, rate: e.target.value })}
                    /> */}

                    <div style={{ marginTop: 60 }}>
                        <Button
                            className="r-primary_btn"
                            type="primary"
                            style={{ width: '100%', marginBottom: 20 }}
                            onClick={() => confirmClick()}
                            loading={loading}
                            disabled={!state.version || !state.minVersion || !state.updataDetail}
                        >
                            Upload
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};
export default Versions;
