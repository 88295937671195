import axios from '../utils/index';
import { Pat } from '@/typings/request';
const settingsApi = {
    getStatisticsInfo(callback: (T: any) => void) {
        axios
            .get(`/statistics-entity/list`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    versionAdd(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/admin/app/version/create`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getVersionList(params: Pat, callback: (T: any) => void) {
        axios
            .get(
                `/admin/app/version/list?pageNum=${params.pageNum}&pageSize=${params.pageSize}&mobileTypes=${params.mobileTypes}`
            )
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    updatePassword(data: Pat, callback: (T: any) => void) {
        axios
            .put(`/admin/changePassword`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
};
export default settingsApi;
